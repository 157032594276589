<template>
  <div>
    <form-wizard
      :color="is_loading.submit ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="is_loading.submit ? 'Updating...' : 'Update'"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- account details tab -->
      <tab-content
        title="Shift Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="shifts-form" class="p-2">
            <b-row>
              <b-col md="6">
                <b-form-group label="Shift Name" label-for="shifts-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Shift Name"
                    rules="required"
                  >
                    <b-form-input
                      id="shifts-name"
                      v-model="data_local.name"
                      name="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Shift Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Shift Status" label-for="shifts-status">
                  <b-form-checkbox
                    v-model="data_local.status"
                    switch
                    class="custom-control-primary"
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Description" label-for="description">
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="data_local.description"
                      name="description"
                      placeholder="Description"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Session 1 Start From"
                  rules="required"
                >
                  <b-form-group
                    label="Session 1 Start From"
                    label-for="session_1_start_from"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      v-model="data_local.session_1_start_from"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="times"
                      :clearable="false"
                      :searchable="false"
                      input-id="session_1_start_from"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Select Session 1 Start From.."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Session 1 End At"
                  rules="required"
                >
                  <b-form-group
                    label="Session 1 End At"
                    label-for="session_1_end_at"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      v-model="data_local.session_1_end_at"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="times"
                      :clearable="false"
                      :searchable="false"
                      input-id="session_1_end_at"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Select Session 1 End At.."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Session 2 Start From"
                  rules="required"
                >
                  <b-form-group
                    label="Session 2 Start From"
                    label-for="session_2_start_from"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      v-model="data_local.session_2_start_from"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="times"
                      :clearable="false"
                      :searchable="false"
                      input-id="session_2_start_from"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Select Session 2 Start From.."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Session 2 End At"
                  rules="required"
                >
                  <b-form-group
                    label="Session 2 End At"
                    label-for="session_2_end_at"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      v-model="data_local.session_2_end_at"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="times"
                      :clearable="false"
                      :searchable="false"
                      input-id="session_2_end_at"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Select Session 2 End At.."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Timezone"
                  rules="required"
                >
                  <b-form-group
                    label="Timezone"
                    label-for="timezone"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      v-model="data_local.timezone"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="timezones"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.name"
                      :filter-by="myTimezoneFilter"
                      :searchable="true"
                      input-id="timezone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Select Timezone.."
                    >
                      <template slot="option" slot-scope="option">
                        <span
                          :class="['flag-icon', `flag icon-${option.name}`]"
                        ></span>
                        {{ option.name }} ({{ option.offset }})
                      </template>

                      <template slot="selected-option" slot-scope="option">
                        <span
                          :class="['flag-icon', `flag icon-${option.name}`]"
                        ></span>
                        {{ option.name }} ({{ option.offset }})
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <b-form-group
                    label="Country"
                    label-for="country"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      v-model="data_local.country"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="country"
                      :filter-by="myCountryFilter"
                      :clearable="false"
                      input-id="country"
                      label="name"
                      :reduce="(option) => option.name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Select Country.."
                    >
                      <template slot="option" slot-scope="option">
                        <span
                          :class="['flag-icon', `flag icon-${option.name}`]"
                        ></span>
                        {{ option.name }} ({{ option.code }})
                      </template>

                      <template slot="selected-option" slot-scope="option">
                        <span
                          :class="['flag-icon', `flag icon-${option.name}`]"
                        ></span>
                        {{ option.name }} ({{ option.code }})
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="working_days"
                  rules="required"
                >
                  <b-form-group
                    label="Working Days"
                    label-for="working_days"
                    :state="errors.length > 0 ? false : null"
                  >
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.working_days"
                    value='1'
                  >
                    Monday
                  </b-form-checkbox>
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.working_days"
                    value='2'
                  >
                    Tuesday
                  </b-form-checkbox>
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.working_days"
                    value='3'
                  >
                    Wednesday
                  </b-form-checkbox>
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.working_days"
                    value='4'
                  >
                  Thursday
                  </b-form-checkbox>
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.working_days"
                    value='5'
                  >
                    Friday
                  </b-form-checkbox>
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.working_days"
                    value='6'
                  >
                    Saturday
                  </b-form-checkbox>
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.working_days"
                    value='0'
                  >
                    Sunday
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

              <b-form-group
                v-for="(item, index) in customFields"
                :key="item.id"
                class="col-md-6"
              >
                <!-- #default="{ errors }" -->
                <validation-provider
                  :name="item.name"
                  rules=""
                  #default="validationContext"
                >
                  <b-form-group
                    v-if="item.type == 'number'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'text'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'url'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="url"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'rating'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <div class="d-flex align-items-center">
                      <star-rating
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :show-rating="false"
                        :star-size="30"
                      ></star-rating>
                      <span
                        v-if="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :class="`rating-btn btn ${
                          ratingdescription[
                            data_local.get_customfields_data['field_' + item.id]
                          ].class
                        }`"
                      >
                        {{
                          ratingdescription[
                            data_local.get_customfields_data["field_" + item.id]
                          ].text
                        }}</span
                      >
                      <input
                        type="hidden"
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'password'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="password"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'textarea'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-textarea
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      trim
                      :placeholder="item.label"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.type == 'date'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <flat-pickr
                      :config="{ dateFormat: 'Y-m-d' }"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      class="form-control"
                      placeholder="Select Date.."
                    />
                  </b-form-group>

                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import shiftStoreModule from "../shiftStoreModule";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";

let timezones = require("/src/libs/timezone.json");
let country = require("/src/libs/CountryCodes.json");

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      is_loading: {
        submit: false,
      },
      redirectShift: "",
      data_local: {
        name: "",
        session_1_start_from: "",
        session_1_end_at: "",
        session_2_start_from: "",
        session_2_end_at: "",
        status: "",
        get_customfields_data: [],
        working_days:[]
      },
      customFields: [],
      quarterHours: ["00", "15", "30", "45"],
      times: [],
      timezones: timezones,
      country: country,
      myTimezoneFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.offset.toLowerCase().indexOf(temp) > -1
        );
      },
      myCountryFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.dial_code.toLowerCase().indexOf(temp) > -1 ||
          option.code.toLowerCase().indexOf(temp) > -1
        );
      },
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-shifts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, shiftStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
    this.fetch_user_data(this.$route.params.id);
    for (var i = 0; i < 24; i++) {
      for (var j = 0; j < 4; j++) {
        if (i < 10) {
          this.times.push("0" + i + ":" + this.quarterHours[j]);
        } else {
          this.times.push(i + ":" + this.quarterHours[j]);
        }
      }
    }
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    fetch_user_data(userId) {
      this.$store
        .dispatch("app-shifts/fetchShift", userId)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
          // if (err.response.status === 404) {
          //   return;
          // }
          console.error(err);
        });
    },
    formSubmitted() {
      const self = this;

      this.is_loading.submit = true;
      self.data_local._method = "PATCH";
      this.$store
        .dispatch("app-shifts/updateShift", {
          id: self.$route.params.id,
          data: self.data_local,
        })
        .then((res) => {
          this.is_loading.submit = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Shift Updated",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.$router.push("/shifts");
        })
        .catch((error) => {
          this.is_loading.submit = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Oops! Shift updating Failed",
                icon: "BellIcon",
                variant: "danger",
                text: error,
              },
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-shifts/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          self.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Oops! Custom Fields Getting Failed",
                icon: "BellIcon",
                variant: "danger",
              },
            });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--credits {
  display: none;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
